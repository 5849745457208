import React from "react";
import ReactPlayer from "react-player";
import {graphql, useStaticQuery, Link} from "gatsby";
import {isEmpty} from "lodash";
// import {format as dateFormat} from "date-fns";
import Markdown from "markdown-to-jsx";
import Img from "components/image";
import DefaultLayout from "components/layout/default";
import {IMAGE_BACKGROUND_PROPS, YOUTUBE_CONFIG} from "constants/index";
import style from "styles/pages/about.module.css";
import layoutStyle from "styles/layout.module.css";
// import eventIcon1 from "images/events-icon-1.svg";
// import eventIcon2 from "images/events-icon-2.svg";
import clientLogo1 from "images/client-propertyguru.svg";
import clientLogo2 from "images/client-tiket.com.svg";
import clientLogo3 from "images/client-gojek.svg";
import clientLogo4 from "images/client-moneysmart.svg";
import partnersMaps from "images/partners-maps.svg";

function AboutPage(props) {
  let data = useStaticQuery(QUERY);
  let pageContent = data.pageContent.childPagesJson;
  // let {events} = data.eventsPageContent.childPagesJson;
  let {phoneNumbers} = data.contactPageContent.childPagesJson;

  // function renderEventsEntries() {
  //   return events.map((event, index) => {
  //     return <div key={`event-${index}`} className="flex justify-between items-start md:items-center flex-col md:flex-row mb-8 md:mb-0">
  //       <div className="font-bold my-0 md:my-2 md:my-4 flex-1">
  //         {event.name}
  //       </div>
  //       <div className="mt-1 md:my-4 w-32">
  //         <img className="inline-block mr-4 sm pointer-events-none"
  //           src={eventIcon1} alt="Calendar Icon"/>
  //         {dateFormat(event.date, "D MMM")}
  //       </div>
  //       <div className="mt-1 md:my-4 w-64">
  //         <img className="inline-block mr-4 pointer-events-none"
  //           src={eventIcon2} alt="Location Icon"/>
  //         {event.locationText}
  //       </div>
  //       <div className="mt-4 md:my-4 flex-none">
  //         <a className="btn btn-sm btn-orange-outline inline-block"
  //           href={event.learnMoreUrl} target="_blank" rel="noopener noreferrer">Learn More</a>
  //       </div>
  //     </div>;
  //   });
  // }

  function renderPhoneNumbers() {
    if (isEmpty(phoneNumbers)) return null;
    return phoneNumbers.map((item) => {
      return <div key={item.number} className="flex">
        <div className="font-bold w-10 mb-1">{item.label}</div>
        <a href={`tel:${item.number}`}>{item.number}</a>
      </div>;
    });
  }

  return <DefaultLayout location={props.location}
    title={pageContent.title} description={pageContent.description}>
    <div className={`${layoutStyle.headerPlaceholder} block lg:hidden`}/>
    <section className="lg:min-h-screen lg:flex items-center">
      <div className="container mx-auto md:px-8 z-10 relative mt-8 lg:mt-0">
        <div className={style.videoPlayer}>
          <ReactPlayer loop controls={false} muted={true} playsinline={true}
            className="absolute top-0 left-0"
            width="100%" height="100%"
            url={pageContent.videoUrl}
            config={{youtube: YOUTUBE_CONFIG}}/>
        </div>
      </div>
    </section>
    <section className="relative min-h-screen flex items-center">
      <div className="container mx-auto px-4 md:px-8 z-20 relative mt-8 mb-32 md:mb-0">
        <h3 className="font-bold text-4xl lg:text-6xl leading-tight whitespace-pre-wrap">
          {pageContent.section1.headerText}
        </h3>
        <div className="mt-4 md:max-w-lg">
          <Markdown options={MARKDOWN_OPTIONS}>
            {pageContent.section1.subHeaderText}
          </Markdown>
        </div>
        <div className="partner logo placeholder mx-auto mt-16 text-center md:text-left">
          <img className={style.clientLogo} src={clientLogo1} alt="PropertyGuru"/>
          <img className={style.clientLogo} src={clientLogo2} alt="Tiket.com"
            style={{padding: "2px 0"}}/>
          <img className={style.clientLogo} src={clientLogo3} alt="Go Jek"
            style={{padding: "3px 0"}}/>
          <img className={style.clientLogo} src={clientLogo4} alt="MoneySmart"
            style={{padding: "6px 0"}}/>
        </div>
      </div>
      <Img className={style.mainTigerImage}
        fluid={data.mainTigerImage.childImageSharp.fluid} alt="Background"/>
      <div className="absolute top-0 left-0 w-full h-full bg-black z-10"
        style={{opacity: "0.7"}}/>
    </section>
    <section className="relative flex items-center mt-20 mb-32">
      <div className="container mx-auto px-4 md:px-8 z-10 relative mb-32 md:mb-0">
        <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight whitespace-pre-wrap">
          {pageContent.section2.headerText}
        </h3>
        <p className="mt-4 text-center mx-auto max-w-4xl">
          {pageContent.section2.subHeaderText}
        </p>
      </div>
    </section>
    {/* <section className="relative min-h-screen flex items-center">
      <div className="container mx-auto px-8 z-10 relative">
        <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight whitespace-pre-wrap max-w-4xl mx-auto">
          Events
        </h3>
        <div className="max-w-4xl mx-auto my-16">
          {renderEventsEntries()}
        </div>
      </div>
    </section> */}
    <section className="relative min-h-screen flex items-center">
      <div className="container mx-auto px-4 md:px-8 z-10 relative">
        <h2 className="font-bold text-xl text-center leading-tight uppercase">
          Careers
        </h2>
        <h3 className="font-bold text-4xl lg:text-6xl text-center leading-tight mt-4 whitespace-pre-wrap">
          Join our fearless journey
        </h3>
        <div className="text-center">
          <Link to="/careers/" className="btn btn-orange mt-6 inline-block">
            View Careers
          </Link>
        </div>
      </div>
      <Img {...IMAGE_BACKGROUND_PROPS}
        fluid={data.careersBackgroundImage.childImageSharp.fluid} alt="Background"/>
    </section>
    <section className="relative min-h-screen flex items-center mb-16 py-16">
      <div className="container mx-auto px-4 md:px-8 z-10 relative">
        <h3 className="font-bold text-4xl lg:text-6xl leading-tight whitespace-pre-wrap max-w-lg">
          {pageContent.section3.headerText}
        </h3>
        <div className="mt-4 max-w-lg">
          <Markdown options={MARKDOWN_OPTIONS}>
            {pageContent.section3.subHeaderText}
          </Markdown>
        </div>
        <div>
          <Link to="/contact/" className="btn btn-orange mt-6 inline-block">
            Contact Us
          </Link>
        </div>
        <div className="mt-20">
          {renderPhoneNumbers()}
        </div>
      </div>
      <img src={partnersMaps} alt="Background"
        style={{position: "absolute", right: "0", width: "1000px", maxWidth: "100%", zIndex: 1, pointerEvents: "none"}}/>
    </section>
  </DefaultLayout>;
}

const MARKDOWN_OPTIONS = {
  overrides: {
    p: {props: {className: "my-4"}}
  }
};

const QUERY = graphql`
  {
    pageContent: file(relativePath: {eq: "pages/about.json"}) {
      childPagesJson {
        title
        description
        videoUrl
        section1 {
          headerText
          subHeaderText
        }
        section2 {
          headerText
          subHeaderText
        }
        section3 {
          headerText
          subHeaderText
        }
      }
    }
    # eventsPageContent: file(relativePath: {eq: "pages/events.json"}) {
    #   childPagesJson {
    #     events {
    #       name
    #       date
    #       locationText
    #       learnMoreUrl
    #     }
    #   }
    # }
    contactPageContent: file(relativePath: {eq: "pages/contact.json"}) {
      childPagesJson {
        phoneNumbers {
          label
          number
        }
      }
    }
    mainTigerImage: file(relativePath: {eq: "main-tiger.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    careersBackgroundImage: file(relativePath: {eq: "background-about-1.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: JPG) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default React.memo(AboutPage);
